'use client';

import { useState } from 'react';
// @mui
import Typography from '@mui/material/Typography';
// routes
// config
// hooks
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import axios from 'axios';
import { HOST_API, PATH_AFTER_LOGIN } from 'src/config-global';
import useScript from 'src/hooks/useScript';
import Iconify from 'src/components/iconify';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { useBoolean } from 'src/hooks/use-boolean';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { useResponsive } from 'src/hooks/use-responsive';
import { useSnackbar } from 'src/components/snackbar';
import { useRouter, useSearchParams } from 'src/routes/hooks';
import { useSignupContext } from 'src/components/signup-popup/context/signup-context';
import { getCookie } from 'cookies-next';
import { fireRegistered } from 'src/utils/tracking';

// ----------------------------------------------------------------------

export default function GoogleLogin({ onlyOneTap = false }) {
  const mdUp = useResponsive('up', 'md');
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { authenticated, logout, handleLoginResponse } = useAuthContext();
  const signup = useSignupContext();
  useScript('https://accounts.google.com/gsi/client');

  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const [mergePassword, setMergePassword] = useState('');
  const [passwordSent, setPasswordSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [previousGoogleResponse, setPreviousGoogleResponse] = useState(null);

  const password = useBoolean();

  const searchParams = useSearchParams();
  const returnTo = searchParams.get('returnTo');

  window.handleGoogleSignIn = (res) => {
    setPreviousGoogleResponse(res);
    handleLogin(res);
  };

  const handleLogin = (res = null) => {
    setIsLoading(true);
    const googleResponse = res || previousGoogleResponse;
    const byAffiliate = getCookie('by_affiliate');
    const signupUrl = window?.location?.href || '';

    axios
      .post(`${HOST_API}/auth/login/google`, {
        ...googleResponse,
        password: mergePassword,
        referral_code: byAffiliate,
        onboarding_source_type: signup?.onboardingSource?.type,
        onboarding_source_alias: signup?.onboardingSource?.alias,
        signup_url: signupUrl
      })
      .then(({ data }) => {
        setIsLoading(false);
        if (data && data.password_required) {
          setIsPasswordDialogOpen(true);
          if (mergePassword) {
            setPasswordSent(true);
          }
          return;
        }
        if (data && data.data.accessToken) {
          // authService.setSession(data.data.access_token)
          // setUser(data.user)

          handleLoginResponse(data);
          handlePasswordDialogClose();
          signup.onClose();

          if (data.is_new_user) {
            fireRegistered('google');
          }
          const redirect = returnTo || signup?.redirect || PATH_AFTER_LOGIN;
          if (redirect !== 'none' && googleResponse?.select_by !== 'auto') {
            router.push(redirect);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(
          `Google login error: ${typeof error === 'string' ? error : error.message}`,
          { variant: 'error' }
        );
        console.error('login error', error.response);
      });
  };

  const handlePasswordDialogClose = () => {
    setMergePassword('');
    setPasswordSent(false);
    setIsPasswordDialogOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      signup.onClose();
      router.replace('/');
    } catch (error) {
      console.error('error logout', error);
    }
  };

  const renderOneTapLogin = (
    <div
      id="g_id_onload"
      data-client_id="828466081675-00nnie7f38ldv6t4gv2t81ae1lfu00l3.apps.googleusercontent.com"
      data-context="signin"
      data-ux_mode="popup"
      data-callback="handleGoogleSignIn"
      data-auto_select="false"
      data-itp_support="true"
    />
  );

  const renderMergeDialog = (
    <Dialog open={isPasswordDialogOpen} onClose={handlePasswordDialogClose} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography>
        <Typography variant="h4">Link Existing Account</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Existing account found for the email. Fill password to link the accounts.
        </DialogContentText>
        <Box mt={1}>
          <TextField
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            value={mergePassword}
            onChange={(event) => {
              setMergePassword(event.target.value);
              setPasswordSent(false);
            }}
            onKeyDown={(event) => event.key === 'Enter' && handleLogin(null)}
            fullWidth
          />
          {passwordSent && (
            <FormHelperText error={passwordSent}>Wrong password, try again!</FormHelperText>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePasswordDialogClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleLogin(null)} color="primary" disabled={password?.length === 0}>
          Link
        </Button>
      </DialogActions>
    </Dialog>
  );

  if (authenticated) {
    return onlyOneTap ? null : (
      <Button size="large" onClick={handleLogout} variant="outlined" color="accent">
        Logout
      </Button>
    );
  }

  if (onlyOneTap) {
    return (
      <Box
        sx={{
          width: 0,
          overflow: 'hidden',
        }}
      >
        {renderOneTapLogin}
        {renderMergeDialog}
      </Box>
    );
  }

  return (
    <>
      <Stack
        alignItems="center"
        sx={{
          display: 'flex',
          background: (theme) => theme.palette.background.paper,
          border: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        {isLoading ? (
          <Box
            sx={{ display: 'flex', alignItems: 'center', height: 38, fontWeight: 600, pr: 1.5 }}
            typography="body2"
          >
            <Iconify icon="svg-spinners:8-dots-rotate" width={18} sx={{ mr: 1 }} /> Signin in...
          </Box>
        ) : (
          <Box sx={{ width: mdUp ? 302 : 252, margin: '-1px', height: 40 }}>
            {renderOneTapLogin}

            <div
              className="g_id_signin"
              data-type="standard"
              data-shape="rectangular"
              data-theme="outline"
              data-text="continue_with"
              data-size="large"
              data-logo_alignment="left"
              data-width={mdUp ? 302 : 252}
            />
          </Box>
        )}
      </Stack>
      {renderMergeDialog}
    </>
  );
}

GoogleLogin.propTypes = {
  onlyOneTap: PropTypes.bool,
};
